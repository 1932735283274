import { AMPLITUDE_EVENTS } from "@/utils/helpers/amplitudeEvents";
import { Button } from "@/UI/Button";
import { classNames } from "@/utils/helpers/classNames";
import { Control, useForm, FieldErrors } from "react-hook-form";
import { ControlledCheckbox } from "@/UI/CheckBox";
import { ControlledInput } from "@/UI/Input";
import { ControlledMultiSelect } from "@/UI/MultiSelect";
import { ControlledSelect } from "@/UI/Select/ControlledSelect";
import { DangerModal } from "@/UI/Modal";
import { FC, useState } from "react";
import { InfoIcon, UserPlusIcon } from "@/assets";
import { LoadingSpinner } from "@/UI/Loading";
import { OptionSchema, Option, MultiSelectOptionSchema } from "@/types/option";
import { StickyHeader } from "@/UI/StickyHeader";
import { toast } from "react-toastify";
import { useAccount } from "@/lib/react-query/queryHooks/useAccount";
import { useAuthContext } from "@/components/Auth/AuthWrapper";
import { useBranches } from "@/lib/react-query/queryHooks/useBranches";
import { useCreateUser } from "@/lib/react-query/mutationHooks/useCreateUser";
import { useDeleteUser } from "@/lib/react-query/mutationHooks/useDeleteUser";
import { useDivisions } from "@/lib/react-query/queryHooks/useDivisions";
import { useEditUser } from "@/lib/react-query/mutationHooks/useEditUser";
import { useNavigate } from "react-router-dom";
import { User } from "@/types/users/general";
import { useResendInvite } from "@/lib/react-query/mutationHooks/useResendInvite";
import { userQueryMapper } from "@/utils/formDefinitions/users/general";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  edit: boolean;
  loading?: boolean;
  user?: User;
};

const defaultOption = {
  id: "",
  value: "",
};

const authMethods = [
  { id: "1", value: "Mobile" },
  { id: "2", value: "Web" },
  { id: "3", value: "Mobile & Web" },
];

const zUserFormSchema = z
  .object({
    assetManagement: z.boolean(),
    authMethod: OptionSchema.refine(
      ({ id, value }) => {
        return Boolean(id.length && value.length);
      },
      { message: "Required" }
    ),
    branch: MultiSelectOptionSchema.refine(
      (value) => {
        return Boolean(value.length);
      },
      { message: "Required" }
    ),
    cellPhone: z.string(),
    disabled: z.boolean(),
    division: MultiSelectOptionSchema.refine(
      (value) => {
        return Boolean(value.length);
      },
      { message: "Required" }
    ),
    email: z.string(),
    firstName: z.string().min(1, { message: "Required" }),
    fullInventoryAccess: z.boolean(),
    jobManagement: z.boolean(),
    lastName: z.string().min(1, { message: "Required" }),
    mobileAccess: z.boolean(),
    occupation: MultiSelectOptionSchema,
    formsManagement: z.boolean(),
    trackerManagement: z.boolean(),
    userManagement: z.boolean(),
    webAccess: z.boolean(),
    salesTools: z.boolean(),
  })
  .superRefine((data, ctx) => {
    // Both following variables needs to be created inside superRefine.
    // Outside variables and/or imports failes to load.
    const validEmail = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/g.test(data.email);
    const validPhoneNumber = data.cellPhone.replace(/\D/g, "").length === 10;

    if (data.cellPhone && !validPhoneNumber) {
      ctx.addIssue({
        path: ["cellPhone"],
        message: "Invalid Phone Number",
        code: "custom",
      });
    }

    if (data.email && !validEmail) {
      ctx.addIssue({
        path: ["email"],
        message: "Invalid Email",
        code: "custom",
      });
    }

    switch (data.authMethod.id) {
      case "1":
        if (!data.cellPhone) {
          ctx.addIssue({
            path: ["cellPhone"],
            message: "Required",
            code: "custom",
          });
        } else if (!validPhoneNumber) {
          ctx.addIssue({
            path: ["cellPhone"],
            message: "Invalid Phone Number",
            code: "custom",
          });
        }
        break;
      case "2":
        if (!data.email) {
          ctx.addIssue({
            path: ["email"],
            message: "Required",
            code: "custom",
          });
        } else if (!validEmail) {
          ctx.addIssue({
            path: ["email"],
            message: "Invalid Email",
            code: "custom",
          });
        }
        break;
      case "3":
        if (!data.cellPhone) {
          ctx.addIssue({
            path: ["cellPhone"],
            message: "Required",
            code: "custom",
          });
        } else if (!validPhoneNumber) {
          ctx.addIssue({
            path: ["cellPhone"],
            message: "Invalid Phone Number",
            code: "custom",
          });
        }

        if (!data.email) {
          ctx.addIssue({
            path: ["email"],
            message: "Required",
            code: "custom",
          });
        } else if (!validEmail) {
          ctx.addIssue({
            path: ["email"],
            message: "Invalid Email",
            code: "custom",
          });
        }
        break;

      default:
        break;
    }
  });

export type UserFormSchema = z.infer<typeof zUserFormSchema>;

const userFormDefaultValues: UserFormSchema = {
  assetManagement: false,
  authMethod: defaultOption,
  branch: [],
  cellPhone: "",
  disabled: false,
  division: [],
  email: "",
  firstName: "",
  fullInventoryAccess: false,
  jobManagement: false,
  lastName: "",
  mobileAccess: true,
  occupation: [],
  formsManagement: false,
  trackerManagement: false,
  userManagement: false,
  webAccess: false,
  salesTools: false,
};

type InfoCheckBoxProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, object>;
  name: string;
  checkboxSideText: string;
  infoText: string;
  hiddenContentContainerClasses?: string;
};

const InfoCheckbox: FC<InfoCheckBoxProps> = ({
  checkboxSideText,
  control,
  infoText,
  name,
  hiddenContentContainerClasses = "",
}) => {
  return (
    <div className="flex h-10 w-[214px] shrink-0 items-center gap-x-[6px] rounded-[4px] border border-[#B6B6B6] px-2 hover:border-palette-companyGreen">
      <ControlledCheckbox
        className="!h-[18px] !w-[18px]"
        control={control}
        id={name}
        name={name}
        sideText={checkboxSideText}
      />

      <div className="group relative">
        <InfoIcon />

        <div
          className={classNames(
            "absolute -right-[130px] bottom-6 hidden h-max w-max items-center justify-center rounded-md bg-transparent group-hover:flex group-hover:flex-col",
            hiddenContentContainerClasses
          )}
        >
          <div className="flex h-[100px] w-[270px] items-center justify-center overflow-auto rounded-md bg-white shadow-[0px_1px_14px_0px_rgba(0,0,0,0.20)]">
            <p className="w-[238px] text-xs font-normal">{infoText}</p>
          </div>
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderTop: "10px solid white",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

const UserForm: FC<Props> = ({ edit = false, user, loading }) => {
  const [authMethod, setAuthMethod] = useState<Option>(defaultOption);
  const [formWarning, setFormWarning] = useState(false);
  const [disableWarning, setDisableWarning] = useState(false);

  const { accountId } = useAuthContext();
  const { data: useAccountRes, isLoading: useAccountLoading } = useAccount();
  const { data: useBranchesRes, isLoading: useBranchesLoading } = useBranches();
  const { data: useDivisionsRes, isLoading: useDivisionsLoading } =
    useDivisions();
  const { mutateAsync: resendInvite, isPending: useResendInviteLoading } =
    useResendInvite();
  const { mutateAsync: createUser, isPending: useCreateUserLoading } =
    useCreateUser();
  const { mutateAsync: updateUser, isPending: useEditUserLoading } =
    useEditUser();
  const { mutateAsync: disableUser, isPending: useDisableUserLoading } =
    useDeleteUser();
  const navigate = useNavigate();

  const divisionOptions = useDivisionsRes?.data.map((division) => ({
    value: division.id || "",
    label: division.divisionName || "",
  }));

  const branchOptions = useBranchesRes?.data.map((branch) => ({
    value: branch.id,
    label: branch.name,
  }));

  const occupationOptions = useAccountRes?.data.occupations?.map(
    (occupation) => ({ value: occupation.id, label: occupation.name })
  );

  const parseFormValues = (userData: User) => {
    return {
      assetManagement: userData.permissions.includes("web:assetcrud"),
      authMethod:
        userData.authMethod?.toLowerCase() === "both"
          ? authMethods[2]
          : authMethods.find(
              (auth) => auth.value?.toLowerCase() === userData.authMethod
            ) || defaultOption,
      branch:
        branchOptions?.filter((branch) =>
          userData.branchIds.includes(branch.value)
        ) || [],
      cellPhone: userData.phone_number
        ? userData.phone_number.replace(/^\+1/, "")
        : "",
      disabled: userData.disabled ?? false,
      division:
        divisionOptions?.filter((division) =>
          userData.divisionIds?.includes(division.value)
        ) || [],
      email: userData.email ?? "",
      firstName: userData.firstName,
      fullInventoryAccess: userData.permissions.includes("mobileassets:user"),
      jobManagement: userData.permissions.includes("web:schedule"),
      lastName: userData.lastName,
      mobileAccess: true,
      occupation:
        occupationOptions?.filter((occupation) =>
          userData.occupations?.includes(occupation.value)
        ) || [],
      formsManagement: userData.permissions.includes("timecard:admin"),
      trackerManagement: userData.permissions.includes("mobile:provisioning"),
      userManagement: userData.permissions.includes("web:usercrud"),
      webAccess: userData.permissions.includes("web:assetmap"),
      salesTools: userData.permissions.includes("web:quoting"),
    };
  };

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    clearErrors,
    watch,
  } = useForm<UserFormSchema>({
    defaultValues: {
      ...userFormDefaultValues,
    },
    ...(edit && user && { values: parseFormValues(user) }),
    resolver: zodResolver(zUserFormSchema),
  });

  const webAccess = watch("webAccess");

  const handleChangeAuthMethod = (option: Option | undefined) => {
    clearErrors(["email", "cellPhone"]);
    if (option) setAuthMethod(option);
  };

  const onSubmit = async (data: UserFormSchema) => {
    const mappedData = userQueryMapper(data, accountId);

    if (edit && user) {
      const addedPermissions = mappedData.metadata.permissions.filter(
        (p) => !user.permissions.includes(p)
      );
      const removedPermissions = user.permissions.filter(
        (p) => !mappedData.metadata.permissions.includes(p)
      );

      try {
        await updateUser({ props: mappedData, userId: user.id });

        toast.success("User edited.");

        if (addedPermissions.length || removedPermissions.length) {
          amplitude.track(AMPLITUDE_EVENTS.ACCOUNT_PERMISSIONS_UPDATED, {
            ...(addedPermissions.length && {
              permissions_added: addedPermissions,
            }),
            ...(removedPermissions.length && {
              permission_removed: removedPermissions,
            }),
          });
        }
      } catch (error) {
        toast.error("Error editing user.");
      }
    } else {
      try {
        const {
          data: { id },
        } = await createUser({ props: mappedData });

        toast.success("User created.");

        amplitude.track(AMPLITUDE_EVENTS.USER_CREATED, {
          permissions: mappedData.metadata.permissions,
          user_name: `${mappedData.metadata.firstName} ${mappedData.metadata.lastName}`,
        });

        navigate(`/users/user-detail?userId=${id}`);
      } catch (error) {
        toast.error("Error creating user.");

        if (error instanceof Error) {
          amplitude.track(AMPLITUDE_EVENTS.USER_CREATION_FAILED, {
            reason: error.message,
            user_name: `${mappedData.metadata.firstName} ${mappedData.metadata.lastName}`,
          });
        } else {
          amplitude.track(AMPLITUDE_EVENTS.USER_CREATION_FAILED, {
            reason: `API error: ${error}`,
            user_name: `${mappedData.metadata.firstName} ${mappedData.metadata.lastName}`,
          });
        }
      }
    }
  };

  const onDisableUser = async () => {
    if (!user) return;

    try {
      await disableUser({ userId: user.id });

      toast.success("User disabled.");

      navigate("/users");
    } catch (error) {
      toast.error("Error disabling user.");
    }
  };

  const onResendInvite = async () => {
    if (edit && user) {
      try {
        await resendInvite({ id: user.id });

        toast.success("Invitation sent.");
      } catch (error) {
        toast.error("Failed to resend invite.");
      }
    }
  };

  const onError = (data: FieldErrors<UserFormSchema>) => {
    type ObjKeys = keyof typeof data;

    const keys = Object.keys(data) as Array<ObjKeys>;

    if (keys.some((key) => data[key]?.message === "Required")) {
      setFormWarning(true);
    }
  };

  const innerLoading =
    loading ||
    useAccountLoading ||
    useBranchesLoading ||
    useDivisionsLoading ||
    useResendInviteLoading ||
    useCreateUserLoading ||
    useEditUserLoading ||
    useDisableUserLoading;

  return (
    <div className="h-full min-h-max">
      {innerLoading && <LoadingSpinner />}

      <form
        className="h-full w-[729px] gap-y-6 rounded-b-lg rounded-t-lg border bg-palette-white"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <StickyHeader headerClasses="!left-[224px] !bg-[#fcfcfc]">
          <header className="flex h-[80px] w-full items-center justify-between bg-[#fcfcfc] px-6">
            <h1 className="text-3xl font-semibold">User Details</h1>

            <div className="flex items-center gap-x-6">
              <Button
                className="rounded-md"
                label="Cancel"
                type="button"
                variant="secondary"
                onClick={() => navigate("/users")}
              />

              <Button
                className="rounded-md text-white"
                disabled={!isDirty}
                label={edit ? "Save User" : "Create User"}
                type="submit"
                variant="primary"
              />
            </div>
          </header>
        </StickyHeader>

        <header className="mb-6 flex h-[80px] w-full items-center justify-between rounded-t-lg border-b bg-[#fcfcfc] px-6">
          <h1 className="text-3xl font-semibold">User Details</h1>

          <div className="flex items-center gap-x-6">
            <Button
              className="rounded-md"
              label="Cancel"
              type="button"
              variant="secondary"
              onClick={() => navigate("/users")}
            />

            <Button
              className="rounded-md text-white"
              disabled={!isDirty}
              label={edit ? "Save User" : "Create User"}
              type="submit"
              variant="primary"
            />
          </div>
        </header>

        <div className="flex h-max flex-col gap-y-6 rounded-b-lg">
          <section className="flex flex-col gap-y-6 px-6">
            <div className="flex gap-x-3">
              <ControlledInput
                control={control}
                inputClasses="!w-[211px]"
                label="First Name*"
                name="firstName"
                placeholder="Required"
              />

              <ControlledInput
                control={control}
                inputClasses="!w-[211px]"
                label="Last Name*"
                name="lastName"
                placeholder="Required"
              />
            </div>

            <div className="flex gap-x-3">
              <ControlledMultiSelect
                control={control}
                label="Division*"
                name="division"
                options={divisionOptions}
                placeholder="Required"
                selectStyle={{ width: "211px" }}
              />

              <ControlledMultiSelect
                control={control}
                label="Branch*"
                name="branch"
                options={branchOptions}
                placeholder="Required"
                selectStyle={{ width: "211px" }}
              />

              <ControlledMultiSelect
                control={control}
                label="Occupation"
                name="occupation"
                options={occupationOptions}
                placeholder="Optional"
                selectStyle={{ width: "211px" }}
              />
            </div>
          </section>

          <hr />

          <section className="flex flex-col gap-y-4 px-6">
            <h1 className="text-[18px] font-semibold text-palette-black">
              Account Access
            </h1>

            <div className="flex">
              <div className="flex w-1/2 flex-col gap-y-2">
                <ControlledCheckbox
                  className="!h-[18px] !w-[18px] !rounded-sm !p-0"
                  control={control}
                  checked
                  disabled
                  name="mobileAccess"
                  sideText="Mobile Access"
                />
                <span className="w-[328px] text-xs font-medium leading-5 text-[#797979]">
                  Mobile access is available to all users associated with your
                  company’s Ribbiot account.
                </span>
              </div>

              <div className="flex w-1/2 flex-col gap-y-2">
                <ControlledCheckbox
                  className="!h-[18px] !w-[18px] !rounded-sm !p-0"
                  control={control}
                  id="web-access"
                  name="webAccess"
                  sideText="Web Access"
                />

                <span className="w-[328px] text-xs font-medium leading-5 text-[#797979]">
                  Web access is an upgraded account for office, administrative,
                  and scheduling purposes.
                </span>
              </div>
            </div>
          </section>

          <hr />

          <section className="flex flex-col gap-y-4 px-6">
            <div className="flex items-center justify-between">
              <h1 className="text-[18px] font-semibold text-palette-black">
                Login
              </h1>

              <Button
                className="!px-5 !text-[#29b281]"
                disabled={!edit}
                icon={
                  <UserPlusIcon {...(!edit && { pathFill: "#98999e99" })} />
                }
                onClick={onResendInvite}
                label="Resend Invite"
                type="button"
                variant="sexagenary"
              />
            </div>

            <p className="w-[515px] text-xs font-medium text-[#797979]">
              <span className="text-xs font-bold text-[#797979]">
                Authentication Method:{" "}
              </span>
              Select “Email” to allow login via email and password. Select
              “Mobile” to allow users to login via text message. Mobile users
              can login via both Email and/or Text Message. Web users require
              Email Authentication.
            </p>

            <div className="flex gap-x-3">
              <ControlledSelect
                control={control}
                label="Authentication Method*"
                name="authMethod"
                onChange={handleChangeAuthMethod}
                options={authMethods}
                placeholder="Required"
                selectClassNames="!w-[211px]"
              />

              <ControlledInput
                control={control}
                inputClasses="!w-[211px]"
                label={
                  authMethod.id === "2" || authMethod.id === "3"
                    ? "Email*"
                    : "Email"
                }
                name="email"
                placeholder={
                  authMethod.id === "2" || authMethod.id === "3"
                    ? "Required"
                    : "Optional"
                }
              />

              <ControlledInput
                control={control}
                inputClasses="!w-[211px]"
                label={
                  authMethod.id === "1" || authMethod.id === "3"
                    ? "Cell Phone Number*"
                    : "Cell Phone Number"
                }
                name="cellPhone"
                placeholder={
                  authMethod.id === "1" || authMethod.id === "3"
                    ? "Required"
                    : "Optional"
                }
              />
            </div>
          </section>

          <hr />

          <section className="flex flex-col gap-y-4 px-6">
            <h1 className="text-[18px] font-semibold text-palette-black">
              Account Permissions
            </h1>

            <h2 className="text-base font-semibold text-palette-secondaryBlack">
              Mobile Permissions:
            </h2>

            <div className="flex gap-x-3">
              <InfoCheckbox
                checkboxSideText="Tracker Management"
                control={control}
                infoText="Allows users to create and edit assets and provision Ribbiot Pods and Portals from the mobile application."
                name="trackerManagement"
                hiddenContentContainerClasses="-right-10"
              />

              <InfoCheckbox
                checkboxSideText="Full Inventory Access"
                control={control}
                infoText="Allows users to filter, search, and view details of all account assets from the mobile application."
                name="fullInventoryAccess"
                hiddenContentContainerClasses="-right-10"
              />
            </div>
          </section>

          {webAccess && (
            <section className="px-3">
              <div className="flex flex-col gap-y-4 rounded-md border border-[#87e4b8] bg-[#f8fefa] p-3">
                <h2 className="text-base font-semibold text-palette-secondaryBlack">
                  Web Permissions:
                </h2>

                <div className="flex gap-x-3">
                  <InfoCheckbox
                    checkboxSideText="Job Management"
                    control={control}
                    infoText="Allows users to create, edit, and assign users and equipment to Jobs on Web."
                    name="jobManagement"
                    hiddenContentContainerClasses="-right-10"
                  />

                  <InfoCheckbox
                    checkboxSideText="Form Management"
                    control={control}
                    infoText="Allows users to view, edit, and approve forms on Web."
                    name="formsManagement"
                    hiddenContentContainerClasses="-right-10"
                  />

                  <InfoCheckbox
                    checkboxSideText="Asset Management"
                    control={control}
                    infoText="Allows users to create, edit, and delete Assets and Asset information on Web."
                    name="assetManagement"
                    hiddenContentContainerClasses="-right-7"
                  />
                </div>

                <div className="flex gap-x-3">
                  <InfoCheckbox
                    checkboxSideText="User Management"
                    control={control}
                    infoText="Allows for a user to create, edit, and deactivate users for their organization on Web."
                    name="userManagement"
                    hiddenContentContainerClasses="-right-10"
                  />
                  <InfoCheckbox
                    checkboxSideText="Sales Tools"
                    control={control}
                    infoText="Allows users to create and edit quotes on Web."
                    name="salesTools"
                  />
                </div>
              </div>
            </section>
          )}

          {edit && (
            <>
              <hr />

              <section className="px-6 pb-3">
                <Button
                  className="rounded-md"
                  label="Deactivate Account"
                  onClick={() => setDisableWarning(true)}
                  type="button"
                  variant="secondary"
                />
              </section>
            </>
          )}
        </div>
      </form>

      <DangerModal
        confirmAction={() => setFormWarning(false)}
        confirmLabel="Dismiss"
        message="Please fill out all required fields."
        open={formWarning}
        showCancel={false}
        title="Required Fields Missing"
      />

      <DangerModal
        cancelAction={() => setDisableWarning(false)}
        confirmAction={onDisableUser}
        confirmLabel="Confirm"
        message={
          <span className="text-lg font-medium">
            Are you sure you want to deactivate {user?.firstName || ""}
            {user?.lastName || ""}? <br /> This cannot be undone
          </span>
        }
        open={disableWarning}
        title="Deactivate User"
      />
    </div>
  );
};

export default UserForm;
